<script>
	import { t, dayjs } from '@woltair/translations-package-fe'
	import { EXPERT_ADVICE } from '$lib/routes'
	import { countVisiblePages } from '$lib/utils'
	export let totalPages = 1
	export let topics = null
	export let showIsAnswered = true
	export let marginTop = '24px'
	export let marginBottom = '75px'
	export let pageNum = 1
	export let pathname = `/${EXPERT_ADVICE}`
	$: visiblePages = countVisiblePages(pageNum || 1, totalPages)
</script>

<section style:margin-bottom={marginBottom} style:margin-top={marginTop}>
	{#each topics as topic}
		{#if showIsAnswered}
			<div class="query" itemscope itemprop="mainEntity" itemtype="http://schema.org/Question">
				<a
					href="/{EXPERT_ADVICE}{topic?.attributes?.legacy
						? `/${topic.attributes?.topicCategory?.data?.attributes?.slug}`
						: ''}/{topic.attributes?.slug}"
					class="!pl-0"
				>
					<meta
						itemprop="url"
						content="/{EXPERT_ADVICE}{topic?.attributes?.legacy
							? `/${topic.attributes?.topicCategory?.data?.attributes?.slug}`
							: ''}/{topic.attributes?.slug}"
					/>
					<h3 itemprop="name" class="unstyled leading-0 my-2 text-xl">
						{topic?.attributes?.title}
					</h3>
					<div class="flex">
						<span class="subtext mr-1 flex items-center text-base"
							>{dayjs(topic.attributes?.createdAt, 'YYYYMMDD').fromNow()}</span
						>
						<span class="subtext flex items-center">
							{#if topic.attributes?.answer}
								<div itemscope itemprop="acceptedAnswer" itemtype="http://schema.org/Answer">
									<div itemprop="text" class="text-sm text-green-500">
										{t('web.expert_advice.answered')}
									</div>
								</div>
							{:else}
								<div itemscope itemprop="suggestedAnswer" itemtype="http://schema.org/Answer">
									<div itemprop="text" class="text-sm text-red-500">
										{t('web.expert_advice.unanswered')}
									</div>
								</div>
							{/if}
						</span>
					</div>
				</a>
			</div>
		{:else}
			<div class="query">
				<a
					href="/{EXPERT_ADVICE}{topic?.attributes?.legacy
						? `/${topic.attributes?.topicCategory?.data?.attributes?.slug}`
						: ''}/{topic.attributes?.slug}"
					class="!pl-0"
				>
					<h3 class="unstyled leading-0 my-2 text-lg">{topic?.attributes.title}</h3>
					<div class="flex">
						<span class="subtext mr-1 flex items-center text-base"
							>{dayjs(topic.attributes?.createdAt, 'YYYYMMDD').fromNow()}</span
						>
					</div>
				</a>
			</div>
		{/if}
	{/each}
	{#if totalPages > 1}
		<div class="text-center">
			{#each visiblePages as thispage, i (i)}
				{#if typeof thispage === 'number'}
					<a
						href="{pathname}/{thispage}"
						class="btn !my-10 {pageNum === thispage
							? 'btn-filled-primary'
							: 'btn-outline-accent-red'}"
					>
						{thispage}
					</a>
				{:else}
					<div class="btn !my-10">
						{thispage}
					</div>
				{/if}
			{/each}
		</div>
	{/if}
</section>

<style lang="scss">
	section {
		.query {
			border-bottom: 1px solid #ccc;
			transition: 0.3s;

			@media (hover: hover) {
				&:hover {
					background: rgba(220, 220, 220, 0.5);
					cursor: pointer;
				}
			}

			a {
				color: #e20613;
				font-size: 18px;
				font-weight: bold;
				padding: 15px;
				display: block;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;

				@media only screen and (max-width: 760px) {
					display: flex;
					flex-direction: column;
				}

				div {
					@media only screen and (max-width: 760px) {
						margin-top: 15px;
						display: flex;
						justify-content: space-between;
					}
				}
			}

			span {
				color: #666;
				font-weight: normal;
			}
		}
	}
</style>
